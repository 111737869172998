@import url("./components/documentViewer/PdfViewer.css");
@import "@alaffia-technology-solutions/theme/themes/autodor.css";
@import "@alaffia-technology-solutions/components/styles/ag-grid-theme.css";
@import "@alaffia-technology-solutions/pdf-viewer/styles/foxit.css";
@import "@alaffia-technology-solutions/pdf-viewer/styles/pdf-viewer.css";

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  ::before,
  ::after {
    border-style: solid;
    border-width: 0;
  }
}

@layer utilities {
  .autodor-reset {
    @apply [&_h1]:af-mb-0;
    @apply [&_h2]:af-mb-0;
    @apply [&_h3]:af-mb-0;
    @apply [&_h4]:af-mb-0;
    @apply [&_h5]:af-mb-0;
    @apply [&_h6]:af-mb-0;
    @apply [&_ul]:af-mb-0;
    @apply [&_ul]:af-pl-0;
    @apply [&_ul]:af-list-none;
    @apply [&_a]:af-text-inherit;
  }
}
